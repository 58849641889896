@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  user-select: none;
  outline: none;
  margin: 0 auto; /* Asegura el centrado horizontal */
  overflow: hidden; /* Oculta el contenido que se desborda */
  max-width: 90%; /* Evita que el ancho exceda el contenedor */
  box-sizing: border-box; /* Incluye padding y bordes en el ancho total */
  overflow-wrap: break-word;
}

@media (max-width: 1080px) {
  .App {
      width: 45em;
  }

}

@media (max-width: 769px) {
  .App {
      width: 31em;
  }

}

@media (max-width: 480px) {
  .App {
      width: 20em;
  }

}

.vibrate {
  animation: vibrate 0.3s infinite; /* Duración y repetición infinita */
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}
