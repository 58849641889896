@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
html, body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Fondo y expansión dinámica */
  background: linear-gradient(135deg, #f9c4d2 25%, #fbd6c6 50%, #f6f5c0 75%);
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh; /* Garantiza que se adapte al contenido */
  overflow-x: hidden; /* Evita desplazamiento horizontal innecesario */
  
}

code {
  font-family:"Poppins", sans-serif;
}

/* Para el efecto de crayón */
body::after {
  content: '';
  position: fixed; /* Fijo para evitar cortes visuales */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.transparenttextures.com/patterns/scribble-light.png');
  opacity: 0.3; /* Ajusta la opacidad del patrón */
  pointer-events: none; /* No interfiere con el contenido clicable */
  z-index: -1; /* Envía la textura detrás del contenido */
}
