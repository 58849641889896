/* Box about section */
.box-about {
    width: 90%; /* Usamos un ancho porcentual para mejor adaptación */
    max-width: 60em; /* Limita el ancho máximo */
    margin: 10px auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center; /* Centra todo el contenido dentro del contenedor */
    overflow: hidden;
    font-family: 'Poppins', sans-serif; /* Font family */
    box-sizing: border-box; /* Incluye padding y bordes en el ancho total */
}

/* Media queries para dispositivos más pequeños */
@media (max-width: 1170px) {
    .box-about { 
        width: 90%; /* Asegura que el contenedor no sea más grande que la pantalla */
        max-width: 45em; 
    }
}

@media (max-width: 1080px) {
    .box-about { 
        width: 90%; /* Asegura que el contenedor no sea más grande que la pantalla */
        max-width: 45em; 
    }
}

@media (max-width: 768px) {
    .box-about { 
        width: 90%; /* Asegura que el contenedor no sea más grande que la pantalla */
        max-width: 31em; 
    }
}

@media (max-width: 480px) {
    .box-about { 
        width: 90%; /* Asegura que el contenedor no sea más grande que la pantalla */
        max-width: 25em; 
    }
}

.accordion-item {
    margin: 0.7em auto;
}

.accordion-question {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.accordion-question h3 {
    margin: 0;
    text-align: center;
    flex: 1; /* Esto hace que el h3 ocupe el máximo espacio posible */
}

.chevron-icon {
    margin-left: 10px; /* Espacio entre el icono y el texto */
    display: flex;
    align-items: center; /* Alinea verticalmente el icono */
}

.accordion-answer {
    padding: 10px;
}

.answer-text {
    margin: 0 auto;
    text-align: center;
    width: 90%; /* Ancho porcentual para adaptarse a la pantalla */
    max-width: 60em; /* Limita el ancho máximo para pantallas grandes */
    box-sizing: border-box; /* Asegura que padding y bordes se incluyan en el ancho */
}

/* Media queries para ajustar el ancho del párrafo en diferentes tamaños de pantalla */
@media (max-width: 1170px) {
    .answer-text {
        width: 90%; /* Ancho para pantallas de hasta 1170px */
        max-width: 45em;
    }
}

@media (max-width: 1080px) {
    .answer-text {
        width: 90%; /* Ancho para pantallas de hasta 1080px */
        max-width: 31em;
    }
}

@media (max-width: 768px) {
    .answer-text {
        width: 90%; /* Ancho para pantallas de hasta 768px */
        max-width: 31em;
    }
}

@media (max-width: 480px) {
    .answer-text {
        width: 85%; /* Ancho para pantallas de hasta 480px */
        max-width: 25em;
    }
}
