.transfer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
  }
  
  .amount-input::-webkit-outer-spin-button, 
  .amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Elimina los botones en navegadores basados en WebKit */
    margin: 0;
  }
  
  .amount-input[type="number"] {
    -moz-appearance: textfield; /* Elimina los botones en Firefox */
  }
  
  .amount-input {
    width: 15em;
    padding: 0.5em;
    margin-bottom: 1em;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    transition: border-color 0.3s ease;
  }
  
  .amount-input:focus {
    border-color: #4A90E2;
    outline: none;
  }
  
  .transfer-button {
    padding: 0.75em 2em;
    font-size: 1.2em;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .transfer-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .transfer-button:hover:not(:disabled) {
    background-color: #357ABD;
    transform: scale(1.05);
  }
  