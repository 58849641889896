/* Contenedor general */
.language-switcher {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    margin-top: 1em;
}

/* Texto explicativo */
.switcher-text {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    text-align: center;
}

/* Contenedor de los botones */
.language-buttons {
    display: flex;
    gap: 1em;
    justify-content: center;
}

/* Estilo de los botones */
.button-language {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Icono del idioma */
.language-icon {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
}

/* Efecto hover en botones */
.button-language:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Responsivo */
@media (max-width: 480px) {
    .language-switcher {
        gap: 0.3em;
    }

    .switcher-text {
        font-size: 1em;
    }

    .language-icon {
        width: 2em;
        height: 2em;
    }
}
