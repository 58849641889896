/* Contenedor para centrar la imagen */
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden; /* Asegura que la imagen no se desborde */
  }
  
  /* Imagen centrada y ajustada */
  .centered-image {
    max-width: 15em; /* Limita el ancho máximo de la imagen a 10em */
    max-height: 15em; /* Limita la altura máxima de la imagen a 10em */
    object-fit: contain; /* Ajusta la imagen manteniendo su aspecto */
  }
  